import { render, staticRenderFns } from "./SavedPlans.vue?vue&type=template&id=4ccaf7b7&scoped=true&"
import script from "./SavedPlans.vue?vue&type=script&lang=js&"
export * from "./SavedPlans.vue?vue&type=script&lang=js&"
import style0 from "./SavedPlans.vue?vue&type=style&index=0&id=4ccaf7b7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccaf7b7",
  null
  
)

export default component.exports