<template>
  <div class="px-4">
    <div class="card wrapper">
      <img src="@/assets/Icons/close.svg" class="close-image" />
      <div class="title">Diwali Campaign</div>
      <div class="budget">
        <Statistics
          :data="{ label: 'Predicted Mean', value: '423' }"
          :index="1"
        />
      </div>
      <div class="budget">
        <Statistics
          :data="{ label: 'Predicted Mean', value: '423' }"
          :index="2"
        />
      </div>
      <div class="strip-wrapper">
        <StripChart :chartData="digitalData" :showLegend="true" />
      </div>
      <div class="strip-wrapper">
        <StripChart :chartData="contentData" :showLegend="true" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="18.2" unit="K" type="upward" percentage="5" />
        <ComparedColumn :columnData="costData" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="109" unit="" type="upward" percentage="5" />
        <ComparedLine :lineData="conversionData" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="3980" unit="" type="downward" percentage="0" />
        <ComparedColumn :columnData="costData" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="91" unit="" type="downward" percentage="9" />
        <ComparedLine :lineData="conversionData" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="3220042" unit="" type="upward" percentage="5" />
        <ComparedColumn :columnData="costData" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="166" unit="" type="downward" percentage="1.4" />
        <ComparedLine :lineData="conversionData" />
      </div>
      <div class="d-flex justify-content-between stats-wrapper">
        <ComparedStats value="8.6" unit="k" type="upward" percentage="5" />
        <ComparedColumn :columnData="costData" />
      </div>
      <div class="btn-wrapper">
        <button
          class="btn-effect"
          @click="$router.push('/solutions/future-planning/planning-results')"
        >
          <span class="btn-text">View Details</span>
          <i
            class="fas fa-arrow-right ml-5 pl-4 d-flex"
            style="align-items: center"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Statistics from "@/components/Statistics.vue";
import StripChart from "@/components/Chart/StripChart.vue";
import ComparedStats from "@/components/SavedPlans/ComparedStats.vue";
import ComparedLine from "@/components/SavedPlans/ComparedLine.vue";
import ComparedColumn from "@/components/SavedPlans/ComparedColumn.vue";
export default {
  name: "Plan",
  components: {
    Statistics,
    StripChart,
    ComparedStats,
    ComparedLine,
    ComparedColumn,
  },
  data() {
    return {
      digitalData: [
        {
          label: "Google Analytics",
          value: 35,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 20,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 8,
          backgroundColor: "#148f3a",
        },
        {
          label: "Linkedin",
          value: 9,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 15,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 13,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 23,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 77,
          backgroundColor: "#b5a9ff",
        },
      ],
      costData: [
        {
          name: "",
          data: [75, 100, 23, 42, 23, 23, 32, 23],
        },
      ],
      conversionData: [
        {
          data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 3, 3, 2, 1, 4],
          marker: {
            symbol: "circle",
            radius: 10,
            status: {
              hover: {
                enabled: true,
              },
            },
          },
          type: "spline",
          name: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.close-image {
  position: absolute;
  top: 6px;
  right: 6px;
}
.wrapper {
  width: 406px;
  padding: 32px 0 0px 0;
  box-shadow: 0 0 8px 0 #c9ced5;
  position: relative;
}
.title {
  font-size: 20px;
  font-family: ProximaNovaBold;
  color: #222a37;
  margin: 0 0px 57px 29px;
}
.budget {
  margin: 0 0px 57px 24px;
}
.strip-wrapper {
  margin: 0 24px 57px 24px;
}
.stats-wrapper {
  margin: 0 0px 40px 23px;
}
.btn-wrapper {
  width: 402px;
  height: 170px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.38) 0%,
    #fff 63%
  );
  z-index: 99;
  display: flex;
  justify-content: center;
}
.btn-effect {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 208px !important;
  height: 60px !important;
  margin-top: 88px !important;
  object-fit: contain;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>0