<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <Toast
          v-if="toastData.show"
          :type="toastData.type"
          :message="toastData.message"
          :show="toastData.show"
          :time="10000"
          @clearToast="clearToast"
        />
        <!-- Datastream Summary Page -->
        <div class="row">
          <div class="col-md-12">
            <div class="DigitalPlanning-main-rect">
              <b-row class="">
                <b-col
                  ><h5 class="DigitalPlanning-title">Saved Digital Plans</h5>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!-- Datastream sub page -->
        <div class="row">
          <div
            class="col-md-12"
            style="
              max-width: 100% !important;
              overflow-x: auto;
              margin-top: -90px;
            "
          >
            <b-table
              id="spaceTable"
              :items="tableData"
              :fields="fields"
              class="mb-0"
              :total-rows="rows"
              :per-page="perPage"
              responsive="sm"
              ref="selecDataStreamTable"
            >
              <!--<template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  style="width: 20rem"
                />
              </template>-->
              <!-- header checkbox -->
              <!--<template #head(selected)="data">
                <p class="d-none">{{ data }}</p>
                <input
                  type="checkbox"
                  class="form-check-input"
                  @click="selectAll($event)"
                />
              </template>-->
              <template #head()="data">
                <div class="column-heading">{{ data.label }}</div>
              </template>

              <!--<template #cell(selected)="{ rowSelected, index }">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :checked="rowSelected"
                  @change="updateSelectRow(index, $event.target.checked)"
                />
              </template>-->
              <template #cell(Plan_Name)="data">
                <div
                  class="w-100 font-medium content-center"
                  style="
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                  <span class="ml-2 target-name">{{ data.item.planName }}</span>
                </div>
              </template>
                 <template #cell(Campaign_Details)="data">
                <div
                  class="w-100 font-medium content-center"
                  style="
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                <div style="display:flex; flex-direction:column">
                <b-badge style="display:flex; flex-direction:row; color:#000;">
                <div>age - </div>
                  <span style="font-size:12px;" class="ml-2 target-name">{{ data.item.age.toString() }}</span>
                  </b-badge>
          
          <b-badge  style="display:flex; flex-direction:row; color:#000; margin-top:10px">
                <div>market  - </div>
                  <span style="font-size:12px;" class="ml-2 target-name ml-2">{{ data.item.digitalMarket.toString() === "All India" ? "India" : data.item.digitalMarket.toString() }}</span>
                </b-badge>

                    <b-badge  style="display:flex; flex-direction:row; color:#000; margin-top:10px">
                <div>gender - </div>
                  <span style="font-size:12px;" class="ml-2 target-name">{{ data.item.gender.toString()}}</span>
                </b-badge>
                </div>
                </div>
              </template>

              <!-- <template #cell(Plan_Type)="data">
                <div
                  class="w-100 font-medium content-center"
                  style="
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                  <span class="ml-2 target-name">{{ data.item.digitalPLanType }}</span>
                </div>
              </template> -->

             <template #cell(Budget)="data">
                <div
                  class="w-100 font-medium content-center"
                  style="
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                  <!-- <div class="">
                    <div class="badge-wrapper d-flex">
                      <b-badge class="mb-2 mr-2 target-name"> -->
                      {{ data.item.budget }}
                      <!-- </b-badge> -->
<!-- 
                      <b-badge class="mb-2 mr-2 target-name">
                        Age - {{ data.item.age.toString() }}</b-badge
                      >

                      <b-badge class="mb-2 mr-2 target-name">
                        Digital Market - {{ data.item.digitalMarket }}</b-badge
                      > -->
                    <!-- </div>
                  </div> -->
                </div>
              </template> 

           

              <template #cell(Assets_Available)="data">
                <div
                  class="d-flex content-center"
                  style="
                    position: relative;
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                  <div class="d-flex align-items-center h-100 p-1 px-3">
                    <b-badge
                      v-for="(item, index) in data.item.assetsAvailable"
                      :key="index"
                      class="mb-2 mr-2 column-data"
                      >{{ item }}</b-badge
                    >
                  </div>
                </div>
              </template>
              <template #cell(Brand_Category)="data">
                <div
                  class="d-flex content-center"
                  style="
                    position: relative;
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                  <div class="d-flex align-items-center status-section p-3">
                    <div class="content-center" style="font-size: 16px">
                      <span class="">
                        <!--<span class="ml-3"></span>-->
                        <span class="mx-2 target-name"> {{ data.item.brandCategory }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(Created_on)="data">
                <div
                  class="d-flex content-center"
                  style="
                    position: relative;
                    height: 165px;
                    justify-content: start;
                    padding-left: 20px;
                  "
                >
                  <div class="d-flex align-items-center status-section p-3">
                    <div class="content-center" style="font-size: 16px">
                      <span class="">
                        <!--<span class="ml-3"></span>-->
                        <span class="mx-2 target-name"> {{ data.item.dateOfCreation }}</span>
                      </span>
                    </div>
                  </div>
              <w-button style="margin-right:10px; white-space:nowrap;" @buttonClicked="getButtonClicked(data.item)"  :buttonText="'Save Plan'" />
                </div>

              </template>
              <!-- <template #cell(Details)="data">
                <div class="overview-wrapper pb-2">
                  <div class="d-flex slider-wrapper">
                    <div
                      :ref="`slider-${data.index}`"
                      class="d-flex statistics-wrapper"
                    >
                      <div
                        v-for="(stat, index) in data.item.stats"
                        :key="index"
                        style="position: relative"
                      >
                        <Statistics
                          :data="{
                            label: stat.name,
                            value: stat.value,
                            icon: '',
                          }"
                          :index="index"
                          :img="getIcon(stat)"
                          :specialChar="false"
                          :specialCharText="''"
                        />
                        <div
                          v-if="data.item.stats.length - 1 > index"
                          class="item-right-line"
                        ></div>
                      </div>-->

              <!--<div style="position: relative">
                        <div class="item-text__title mb-3">Access Allowed</div>
                        <input type="checkbox" class="form-check-input" />
                        <label for="" class="mr-5 access-label">Admin</label>
                        <input type="checkbox" class="form-check-input" />
                        <label for="" class="mr-2 access-label">User</label>
                      </div>-->
              <!--<div>
                        <h5 class="created-on">
                          Created on:
                          <span style="color: #222a37">{{
                            data.item.createdOn
                          }}</span>
                        </h5>
                        <h5 class="end-date">
                          End date:
                          <span style="color: #222a37">{{
                            data.item.endDate
                          }}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex align-items-center status-section">
                      <span
                        class="channels ml-0 mr-2"
                        v-if="data.item.status == 'Completed'"
                        >100%</span
                      >
                      <span class="channels ml-0 mr-2" v-else
                        >{{ data.item.progressValue }}%</span
                      >
                      <b-progress
                        v-if="data.item.status == 'Completed'"
                        :value="100"
                        :max="max"
                        show-progress
                        animated
                      ></b-progress>
                      <b-progress
                        v-else
                        :value="data.item.progressValue"
                        :max="max"
                        show-progress
                        animated
                      ></b-progress>
                      <span class="channels">
                        <span
                          class="active-oval"
                          v-if="
                            data.item.status == 'Completed' ||
                              data.item.status == 'InProgress'
                          "
                        ></span>
                        <span class="inactive-oval" v-else></span>

                        {{ data.item.status }}</span
                      >
                    </div>
                    <div class="d-flex align-items-center status-section ml-5">
                      <div class="content-center" style="font-size : 16px">
                        <span class="">
                          <span class="ml-3">Number of rows</span>
                          <span class="ml-2">
                            {{ data.item.numberOfrows }}</span
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>-->
            </b-table>
          </div>
        </div>
        <div v-if="tableData.length === 0" class="no-items-wrapper">
          <div class="text-center">
            <img src="@/assets/tech-life-media2.png" alt="" />
            <div v-if="searchedTextValue === ''" class="no-items-description">
              No plans have been added yet in your shared space
            </div>
            <div
              v-else-if="searchedTextValue !== ''"
              class="no-items-description"
            >
              Oops!<br />
              Sorry to say, it seems like no plan has been found
            </div>
          </div>
        </div>
        <!--<w-button
          :buttonLoader="'normal'"
          :buttonText="'Create'"
          @buttonClicked="createPlan"
        >
        </w-button>-->
      </main>
    </div>
  </div>
</template>
<script>
// import Statistics from "@/components/Statistics.vue";
// import Dropdown from "@/widgets/Dropdown.vue";
import "@/assets/style/datepicker/index.css";
import Toast from "@/components/Toast/Toast.vue";
import Button from "@/components/Profile/Button.vue";
import { DataConnection } from "../../services/DataConnectionService.js";
import { DigitalPlanning } from "@/services/DigitalPlanningServices.js";
const digitalPlanning = new DigitalPlanning();

const dataConnection = new DataConnection();
var timer;
export default {
  name: "DataStreamSummary",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filterBy: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  components: {
    // Statistics,
    "w-button": Button,
    Toast,
  },
  watch: {
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
  },
  data() {
    return {
      totalRows: 0,
      tableData: [
      ],
      searchedTextValue: "",
      platforms: [
        { text: "CRMs", id: "CRMs" }, //, disable: true
        { text: "Digital", id: "Digital" },
        { text: "Sponsorship", id: "Sponsorship" }, //, disable: true
        { text: "Billboard", id: "Billboard" }, //, disable: true
        { text: "Outdoor", id: "Outdoor" }, //, disable: true
        { text: "Public relation", id: "Public relation" }, //, disable: true
      ],
      channels: [
        { text: "Preload", id: "Preload" }, //, disable: true
        { text: "Digital media", id: "Digital media" },
        { text: "Analytics", id: "Analytics" }, //, disable: true
        { text: "App Analytics", id: "App Analytics" }, //, disable: true
      ],
      isShow: false,
      isFilterShow: false,
      fields: [
        { key: "Plan_Name", sortable: false },
        { key: "Campaign_Details", sortable: false },
        { key: "Budget", sortable: false },
        // { key: "Budget", sortable: false },
        { key: "Assets_Available", sortable: false },
        { key: "Brand_Category", sortable: false },
        { key: "Created_on", sortable: false },
        // { key: "Details", sortable: false },
      ],
      selected: [],
      rows: null,
      perPage: 1005,
      currentPage: 1,
      overDelete: false,
      overView: false,
      overShare: false,
      filter: null,
      dropdownchannel: "",
      dropdownplatforms: "",
      filterOn: [],
      filteredItems: [],
      selectedFilter: "Select",
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      value: "",
      max: 100,
      users: [],
      selectedUsers: [],
      isSelectedAll: false,
      iconList: [
        { channel: "Google Ads", icon: "google" },
        { channel: "FaceBook", icon: "facebook" },
        { channel: "Instagram", icon: "instagram" },
        { channel: "LinkedIn Ads", icon: "linkedIn" },
        { channel: "Twitter", icon: "twitter" },
        { channel: "Google Display & Video 360", icon: "dv360" },
        { channel: "Microsoft Ads", icon: "microsoft" },
        { channel: "Pinterest", icon: "Pinterest" },
        { channel: "Snapchat", icon: "Snapchat" },
        { channel: "Google Analytics", icon: "google-anal" },
        { channel: "Facebook Insights", icon: "facebook" },
        { channel: "Instagram Insights", icon: "instagram" },
        { channel: "Youtube Insights", icon: "youtube" },
        { channel: "LinkedIn Insights", icon: "linkedin" },
        { channel: "Twitter Insights", icon: "twitter" },
        { channel: "Google Ads Keyword Planner", icon: "google" },
      ],
      count: 0,
    };
  },
  mounted() {
    this.filteredItems = this.items;
  },
  methods: {
    getButtonClicked(e) {
      // console.log(e.id)
      sessionStorage.setItem("currentPlanId", e.id)
      this.$router.push('/saved-plans/saved-digital-plans')
    },
    getAllDigitalPlans() {
      var clientId = sessionStorage.getItem("subId")
      digitalPlanning.getSavedDigitalPlans(clientId).then((res) => {
        // console.log(res)
        // localStorage.setItem("temp_digitalplanning", JSON.stringify(res[0]))
        this.tableData = []

        for(var i = 0; i < res.length; i++) {
          // console.log(res[i])

          this.tableData.push(res[i])
        }
      })
    },
    campaignCreated(e) {
      dataConnection.postNewCampaignData(e).then((res) => {
        this.$refs.unifiedCampaignModal.hideWelcomePage();
        console.log(res);
        this.$refs.dataConnectionSolutionsModal.open("Solutions");
      });
    },
    solutionCreated() {
      console.log("Process Done");
    },
    createPlan() {
      this.$refs.unifiedCampaignModal.open("Unified Campaign");
    },
    updateSelectRow(index, val) {
      if (val) {
        this.$refs.selecDataStreamTable.selectRow(index);
      } else {
        this.$refs.selecDataStreamTable.unselectRow(index);
      }
    },
    createDataStream() {
      this.$refs.dataStreamModal.open("Add data stream");
    },
    streamCreated() {
      this.$refs.dataFetchModal.open("Initial Data Fetch");
    },
    processDone() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    selectFilter(e) {
      const search = e.text;
      if (!search) {
        this.tableData = this.items;
      } else {
        if (
          this.dropdownplatforms != undefined &&
          this.dropdownplatforms &&
          this.dropdownplatforms.id &&
          this.dropdownchannel != undefined &&
          this.dropdownchannel &&
          this.dropdownchannel.id
        ) {
          var searchvalue = this.dropdownplatforms.id;
          var platschannel = this.dropdownchannel.id;
          let result = this.items.filter(function (e) {
            return e.plateForm == searchvalue && e.channel == platschannel;
          });
          this.rows = result.length;
          this.currentPage = 1;
          this.tableData = result;
        } else if (
          this.dropdownplatforms != undefined &&
          this.dropdownplatforms &&
          this.dropdownplatforms.id
        ) {
          var searchvaluedata = this.dropdownplatforms.id;
          let result = this.items.filter(function (e) {
            return e.plateForm == searchvaluedata;
          });
          this.rows = result.length;
          this.currentPage = 1;
          this.tableData = result;
        } else if (
          this.dropdownchannel != undefined &&
          this.dropdownchannel &&
          this.dropdownchannel.id
        ) {
          var searchvaluetwo = this.dropdownchannel.id;
          let result = this.items.filter(function (e) {
            return e.channel == searchvaluetwo;
          });
          this.rows = result.length;
          this.currentPage = 1;
          this.tableData = result;
        }
      }
    },
    onRowSelected(items) {
      console.log("519", items);
      // this.selected = items;
    },
    myRowClickHandler(record) {
      console.log(record);
    },
    // select all checkbox code
    selectAll(event) {
      console.log();
      if (event.target.checked) {
        this.$refs.selecDataStreamTable.selectAllRows();
      } else {
        this.$refs.selecDataStreamTable.clearSelected();
      }
    },
    // select all checkbox end
    clickedSearch(event) {
      this.searchedTextValue = event.target.value.toLowerCase();
      if (this.searchedTextValue.length > 0) {
        this.perPage = 20;
        this.currentPage = 1;
        this.getSearchedTableData(this.searchedTextValue);
      } else {
        this.currentPage = 1;
        this.perPage = 5;
        this.getDataStreamSummaryTable();
      }
    },
    getSelectedPage(event) {
      if (this.searchedTextValue === "") {
        if (event != this.selectedPageNo) {
          this.selectedPageNo = event;
          this.currentPage = event;
          this.perPage = 5;
          this.getDataStreamSummaryTable();
        }
      } else {
        this.selectedPageNo = event;
        this.currentPage = event;
        this.perPage = 20;
        this.getSearchedTableData(this.searchedTextValue);
      }
    },
    getSearchedTableData() {
      dataConnection
        .getsearchDataStream(
          this.searchedTextValue,
          this.currentPage,
          this.perPage
        )
        .then((res) => {
          this.totalRows = 0;
          this.totalRows = res.totalElements;
          this.tableData = [];
          for (var i = 0; i < res.content.length; i++) {
            this.tableData.push({
              ConnectionName: res.content[i].connectionName,
              dataStreamName: res.content[i].datastreamName,
              channel: res.content[i].connectionName,
              createdOn: res.content[i].fetchStartDate,
              endDate: res.content[i].fetchEndDate,
              id: res.content[i].serialNumberl,
              plateForm: res.content[i].channel,
              ChannelName: res.content[i].channel,
              products: [res.content[i].channel],
              progressValue: res.content[i].progress,
              stats: [
                { name: "Channel", value: res.content[i].channel },
                { name: "Brand", value: res.content[i].brandName },
              ],
              brandDetails: { name: "Brand", value: res.content[i].brandName },
              status: res.content[i].status,
              adverityDatastreamId: res.content[i].adverityDatastreamId,
            });
          }
        });
    },
    getDataStreamSummaryTable() {
      clearInterval(timer);
      dataConnection
        .getDataStreamSummaryTableData(this.currentPage, this.perPage)
        .then((res) => {
          this.totalRows = 0;
          this.totalRows = res.totalElements;
          // this.tableData = [];
          // for (var i = 0; i < res.content.length; i++) {
          //   this.tableData.push({
          //     ConnectionName: res.content[i].connectionName,
          //     dataStreamName: res.content[i].datastreamName,
          //     channel: res.content[i].connectionName,
          //     createdOn: res.content[i].fetchStartDate,
          //     endDate: res.content[i].fetchEndDate,
          //     id: res.content[i].serialNumberl,
          //     plateForm: res.content[i].channel,
          //     products: [res.content[i].connectionName],
          //     progressValue: res.content[i].progress,
          //     numberOfrows: res.content[i].numberOfrows,
          //     ChannelName: res.content[i].channel,
          //     brandDetails: { name: "Brand", value: res.content[i].brandName },
          //     stats: [
          //       { name: "Channel", value: res.content[i].channel },
          //       { name: "Brand", value: res.content[i].brandName },
          //     ],
          //     status: res.content[i].status,
          //     adverityDatastreamId: res.content[i].adverityDatastreamId,
          //   });
          //   clearInterval(timer);
          //   var self = this;
          //   if (
          //     this.tableData.find(
          //       (obj) => obj.status.toLowerCase() === "inprogress"
          //     ) !== undefined &&
          //     this.count <= 501
          //   ) {
          //     this.count++;
          //     timer = setTimeout(function () {
          //       return self.getDataStreamSummaryTable();
          //     }, 10 * 1000);
          //   } else {
          //     clearInterval(timer);
          //   }
          // }
        });
    },

    findImage(image) {
      try {
        require("@/assets/connectors/" + image + ".svg");
        return "connectors/" + image + ".svg";
      } catch (error) {
        if (error.code === "MODULE_NOT_FOUND") {
          try {
            require("@/assets/connectors/" + image + ".jpg");
            return "connectors/" + image + ".jpg";
          } catch (error) {
            if (error.code === "MODULE_NOT_FOUND") {
              try {
                require("@/assets/connectors/" + image + ".png");
                return "connectors/" + image + ".png";
              } catch {
                return "google-black.png";
              }
            }
            return "google-black.png";
          }
        }
        return "google-black.png";
      }
    },
  },
  created() {
    this.getDataStreamSummaryTable();
    this.getAllDigitalPlans()
  },
  beforeDestroy() {
    clearInterval(timer);
  },
  beforeCreate() {
    clearInterval(timer);
  },
  computed: {
    getIcon() {
      return (data) => {
        var icon = this.iconList.find((obj) => obj.channel === data.value);
        if (data.name === "Channel") {
          if (icon !== undefined) {
            return this.findImage(icon.icon);
          } else {
            return "google-black.png";
          }
        } else if (data.name === "Brand") {
          return "flag-black.png";
        }
        return "function-fill.png";
      };
    },
  },
};
</script>
<style scoped>
/* >>> .modal-padding {
  padding: 0 0 50px 0 !important;
} */
button.btn-effect-outline:hover {
  color: #fff;
  background-color: #000;
}

/* Datastream main page */
.DigitalPlanning-main-rect {
  /* width: 1514px; */
  height: 126px;
  object-fit: contain;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
  position: relative;
}
.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}
.DigitalPlanning-title {
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  position: relative;
  padding-left: 20px;
  top: 20px;
}

.filter-section {
  top: 20px;
}

.filter-icons {
  margin-right: 15px;
}

.filter-fill {
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: relative;
  top: -3px;
  float: right;
  left: -80px;
}

.searchDark {
  float: right;
  width: 24px;
  height: 24px;
  margin-left: 100px;
  object-fit: contain;
}

.Datastream-subtitle {
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  position: relative;
  left: 20px;
}

.Datastream-subtitle2 {
  position: relative;
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  position: relative;
}

>>> .item-text__subtitle {
  font-size: 20px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.active-oval {
  width: 10px;
  height: 10px;
  margin: 6px 8px 4px 0;
  background-color: #55ea76;
  border-radius: 50%;
  display: inline-block;
}
.inactive-oval {
  width: 10px;
  height: 10px;
  margin: 6px 8px 4px 0;
  background-color: #d8d8d8;
  border-radius: 50%;
  display: inline-block;
}

.item {
  width: 380px;
}

>>> .table > tbody > tr {
  background-color: #ffffff;
}

.created-on {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c9ced5;
  margin-top: 1rem;
}

.end-date {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c9ced5;
  margin-top: 15px;
}
.progress {
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  width: 100px;
  margin-top: 20px;
}
>>> .table-active {
  --bs-table-accent-bg: #eff5ff !important;
  color: var(--bs-table-active-color);
}

>>> tr.b-table-row-selected.table-active {
  background-color: #eff5ff !important;
}

.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}
.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}
.table-top {
  padding: 12px;
}
.name-wrapper {
  padding: 24px;
}
.campagin-name {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.target-name {
  font-family: ProximaNovaBold;
  font-size: 18px;
  color: #222a37;
}
.badge-wrapper {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}
.statistics-wrapper {
  padding: 24px 15px;
  overflow-x: hidden;
}
.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  z-index: 9;
  margin: 8px 0px;
  font-size: 20px;
}
.slider-left {
  box-shadow: 22px 0px 14px #fff;
}
.slider-right {
  box-shadow: -22px 0px 14px #fff;
}
.channels {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  display: flex;
  margin-left: 15px;
}

.btn-effect-outline {
  width: 245px;
  height: 60px;
  border-radius: 3px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: 18px;
  color: #222a37;
  background-color: transparent;
  margin: 9px;
}
.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
>>> .table > thead > tr > th {
  padding: 27px 26px;
}
>>> .table > tbody > tr {
  outline: none;
  box-shadow: 0 0 8px 0#c9ced5;
  border-top-width: 8px;
}
>>> .table > tbody > tr > td {
  padding: 0px;
}
>>> .table > tbody > .table-active > td {
  background-color: #eff5ff;
}
>>> .table > thead > tr > th {
  outline: none;
}
>>> .table > :not(:last-child) > :last-child > * {
  border: none;
}
>>> .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 1.5rem center;
}
input[type="text"] {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  height: 60px;
  font-size: 16px;
}
input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
  box-shadow: none;
}
.image {
  padding: 16px 16px;
}

>>> .page-item.disabled .page-link {
  background-color: transparent;
}
>>> .page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>> .page-link i {
  font-style: normal;
}

>>> .active > .page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37;
  box-shadow: none;
}
.no-items-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
.image-wrapper-active {
  position: relative;
  background-color: #050505;
}
ul.pagination.mb-5.b-pagination.justify-content-end.b-pagination-pills {
  justify-content: flex-start !important;
}
.input-group-prepend {
  background-color: #eff5ff;
}
.badge {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
}

>>> .welcome--modal .vm--modal {
  /* width: 75% !important; */
  /* margin-top: 100px; */
}
.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}
/* >>> table#spaceTable tr td:first-child {
  padding: 24px !important;
  width: 0%;
} */
.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #8394b4;
}
label.access-label {
  font-family: ProximaNovaBold !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #050505;
}
.progress {
  height: 0.5rem;
}
>>> .progress-bar {
  color: #050505 !important;
  background-color: #050505 !important;
}
@media screen and (max-width: 640px) {
  /* >>> .welcome--modal .vm--modal {
    width: 75% !important;
  } */
  >>> p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  >>> p.Together-let-us-hel.ProximaNovaRegular.mt-0 {
    padding: 0px 15px;
    margin-left: 20px;
  }
  >>> .modal-padding {
    padding: 100px 0;
    flex-direction: column !important;
  }
  >>> .row.mt-3.add-fetching-detail-btn {
    margin-left: 6rem !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 640px) {
  /* >>> .welcome--modal .vm--modal {
    width: 75% !important;
  } */
  >>> p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  >>> .modal-padding {
    padding: 100px 0;
    flex-direction: column !important;
  }
  >>> p.Together-let-us-hel.ProximaNovaRegular.mt-0 {
    padding: 0px 15px;
    margin-left: 20px;
  }
  >>> .row.mt-3.add-fetching-detail-btn {
    margin-left: 6rem !important;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  /* >>> .welcome--modal .vm--modal {
    width: 75% !important;
  } */
  >>> p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  .modal-padding {
    padding: 100px 0;
    flex-direction: column !important;
  }
  >>> p.Together-let-us-hel.ProximaNovaRegular.mt-0 {
    padding: 0px 15px;
    margin-left: 20px;
  }
  >>> .row.mt-3.add-fetching-detail-btn {
    margin-left: 6rem !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 900px) {
  /* >>> .welcome--modal .vm--modal {
    width: 90% !important;
  } */
}
@media screen and (max-width: 1200px) {
  /* >>> .welcome--modal .vm--modal {
    width: 90% !important;
  } */
}
</style>
