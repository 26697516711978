<template>
  <div class="row">
    <b-col
      v-for="(row, n) in planRows"
      :key="n"
      cols="12"
      :ref="`wrapper-${n}`"
      class="d-flex wrapper mb-5 p-0"
      @scroll="handleScroll(n)"
    >
      <div v-if="row.showHeader" class="header" style="margin-top: -5px !important">
        <div class="heading-column">
          <div class="heading highlights-wrapper">Highlights</div>
        </div>
        <div  v-for="(data,index) in plans.slice(3 * n, 3 * n + 3)" :key="index+'hjh'" class="px-4">
          <div class="card header-card">
            <img src="@/assets/Icons/close.svg" class="close-image" />
            <div class="title">Diwali Campaign</div>
          </div>
        </div>
      </div>
      
      <div class="heading-column">
        <div class="heading highlights-wrapper">Highlights</div>
        <div class="heading budget-wrapper">Budget</div>
        <div class="heading budget-wrapper">Revenue</div>
        <div class="heading digital-wrapper">Digital</div>
        <div class="heading content-wrapper">Content</div>
        <div class="heading stats-wrapper">Cost</div>
        <div class="heading stats-wrapper">Conversions</div>
        <div class="heading stats-wrapper">Engagements</div>
        <div class="heading stats-wrapper">Views</div>
        <div class="heading stats-wrapper">Impresions</div>
        <div class="heading stats-wrapper">CPA</div>
        <div class="heading stats-wrapper">Metrics Clicks</div>
      </div>
      <div v-for="(data,index) in plans.slice(3 * n, 3 * n + 3)" :key="index+'test'">
        <Plan />
      </div>
    </b-col>
  </div>
</template>
<script>
import Plan from "@/components/SavedPlans/Plan.vue";
export default {
  name: "ComparedPlans",
  props:{
    plans:{
      type: Array,
      default: ()=>[]
    }
  },
  components: {
    Plan,
  },
  data() {
    return {
      // plans: ["plan 1", "plan 2", "plan 3"],
    };
  },
  computed: {
    planRows() {
      let rows = []
      let rowsLength = Math.ceil(this.plans.length / 3);
      for (let index = 0; index < rowsLength; index++) {
       rows.push({showHeader : false})
      }
      return rows
    },
  },
  methods: {
    handleScroll(n) {
      const scrollTop = this.$refs[`wrapper-${n}`][0].scrollTop;
      if (scrollTop > 60 && scrollTop < 1110) {
        this.planRows[n].showHeader = true;
      } else{
        this.planRows[n].showHeader = false;
      }
       this.$forceUpdate()
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: calc(100vh - 210px);
  overflow: auto;
}
.header {
  position: fixed;
  background-color: #f2f5fa;
  height: 117px;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 3px 0 #d2d8e1;
  display: flex;
}
.header-card {
  width: 406px;
  padding: 0px 0 0px 0;
  box-shadow: 0 2px 4px 0 #dce0e7;
}
.title {
  font-size: 20px;
  font-family: ProximaNovaBold;
  color: #222a37;
  margin: 30px 0px 33px 29px;
}
.close-image {
  position: absolute;
  top: 6px;
  right: 6px;
}
.heading-column {
  padding: 32px 0 0 56px;
  width: 216px;
}
.heading {
  font-size: 16px;
  font-family: ProximaNovaBold;
  color: #00312a;
}
.highlights-wrapper {
  height: 87px;
}
.budget-wrapper {
  padding-top: 18px;
  height: 133px;
}
.digital-wrapper {
  /* padding-top: 18px; */
  height: 210px;
}
.content-wrapper {
  /* padding-top: 18px; */
  height: 150px;
}
.stats-wrapper {
  /* padding-top: 10px; */
  height: 122px;
}
</style>