<template>
  <div class="page-wrapper" style="">
    <Toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="2000"
      @clearToast="clearToast"
    />
    <div class="container-fluid">
      <div class="d-block d-sm-flex justify-content-between">
        <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
          <!-- <li class="ProximaNovaBold">
            Saved plans
          </li> -->
          <li :class="{ ProximaNovaBold: !comparePlans }">
            {{ activeSpaceTab }}
          </li>
          <li v-if="comparePlans" class="ProximaNovaBold">Compare plans</li>
        </ul>
        <div v-if="!comparePlans" class="d-flex justify-content-end">
          <!--<w-white-dropdown
            :options="filterDropdown"
            :placeHolder="'Date'"
            :selectedOption="selectedFilter"
            :labelText="''"
            @input="selectFilter($event)"
            width="178px"
            style="z-index: 11"
          ></w-white-dropdown>-->
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div v-if="comparePlans">
        <ComparedPlans :plans="selectedRowData" />
      </div>
      <div v-else class="container-fluid">
        <div>
          <w-tab
            :tabName="activeSpaceTab"
            :tabs="spaceTabs"
            @activeTab="changeSpaceTab($event)"
          >
          </w-tab>
          <div v-if="activeSpaceTab === 'Model'" class="mt-5 d-none">
            <SpaceTable
              :items="tableData"
              @updateToast="updateToast"
              @enableCompare="comparePlans = true"
              @selectedRow="rowDataSelected($event)"
              :selectModeType="modelSelectMode"
              @viewModelResult="viewModelResult($event)"
              @deleteRow="deleteRow($event)"
              @searchInTable="searchByName($event)"
              :loader="searchModelLoader"
            />
            <div class="w-100 d-flex" style="justify-content: end;">
              <Pagination
                :currentPage="currentPage"
                :perPage="rowperPage"
                :totalRows="totalRows"
                @getSelectedPageEvent="getSelectedPageEvent($event)"
              />
            </div>
          </div>
          <div v-if="activeSpaceTab === 'Optimized Result'" class="mt-5 d-none">
            <OptimizedTable
              :items="optimizedTableData"
              @updateToast="updateToast"
              @enableCompare="comparePlans = true"
              @selectedRow="optimizedrowDataSelected($event)"
              :selectModeType="modelSelectMode"
              @viewModelResult="viewOptimizedResult($event)"
              @deleteRow="deleteOptimizedRow($event)"
              @searchInTable="searchByOptimiedName($event)"
              :loader="searchOptimiLoader"
            />
            <div class="w-100 d-flex" style="justify-content: end;">
              <Pagination
                :currentPage="optimizedcurrentPage"
                :perPage="optimizedrowperPage"
                :totalRows="optimizedtotalRows"
                @getSelectedPageEvent="getOptimizedSelectedPageEvent($event)"
              />
            </div>
          </div>
           <div v-if="activeSpaceTab === 'Shortlisted plans'" class="mt-5">
            <DigitalPlanningTable
              :tableTitle="'Saved Digital Plans'"
              :items="digitalPlanningTableData"
              @updateToast="updateToast"
              @enableCompare="comparePlans = true"
              @selectedRow="optimizedrowDataSelected($event)"
              :selectModeType="modelSelectMode"
              @viewModelResult="viewOptimizedResult($event)"
              @deleteRow="deleteOptimizedRow($event)"
              @searchInTable="searchByOptimiedName($event)"
              :loader="searchOptimiLoader"
            />
            <div class="w-100 d-flex" style="justify-content: end;">
              <Pagination
                :currentPage="optimizedcurrentPage"
                :perPage="optimizedrowperPage"
                :totalRows="optimizedtotalRows"
                @getSelectedPageEvent="getOptimizedSelectedPageEvent($event)"
              />
            </div>
          </div>

              <div v-if="activeSpaceTab === 'Saved plans'" class="mt-5">
            <DigitalPlanningTable
              :tableTitle="'Saved Digital Plans'"
              :items="digitalPlanningTableData"
              @updateToast="updateToast"
              @enableCompare="comparePlans = true"
              @selectedRow="optimizedrowDataSelected($event)"
              :selectModeType="modelSelectMode"
              @viewModelResult="viewOptimizedResult($event)"
              @deleteRow="deleteOptimizedRow($event)"
              @searchInTable="searchByOptimiedName($event)"
              :loader="searchOptimiLoader"
            />
            <div class="w-100 d-flex" style="justify-content: end;">
              <Pagination
                :currentPage="optimizedcurrentPage"
                :perPage="optimizedrowperPage"
                :totalRows="optimizedtotalRows"
                @getSelectedPageEvent="getOptimizedSelectedPageEvent($event)"
              />
            </div>
          </div>
             <div v-if="activeSpaceTab === 'Shortlisted Digital Plan'" class="mt-5 d-none">
            <DigitalPlanningTable
              :tableTitle="'Shortlisted Digital Plans'"
              :items="digitalPlanningTableData"
              @updateToast="updateToast"
              @enableCompare="comparePlans = true"
              @selectedRow="optimizedrowDataSelected($event)"
              :selectModeType="modelSelectMode"
              @viewModelResult="viewOptimizedResult($event)"
              @deleteRow="deleteOptimizedRow($event)"
              @searchInTable="searchByOptimiedName($event)"
              :loader="searchOptimiLoader"
            />
            <div class="w-100 d-flex" style="justify-content: end;">
              <Pagination
                :currentPage="optimizedcurrentPage"
                :perPage="optimizedrowperPage"
                :totalRows="optimizedtotalRows"
                @getSelectedPageEvent="getOptimizedSelectedPageEvent($event)"
              />
            </div>
          </div>
        </div>
        <!--<span>
              @getSelectedPageEvent="getOptimizedSelectedPageEvent($event)"
              @searchInTable="searchByName($event)"</span>-->
      </div>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import SpaceTable from "@/components/Table/ModelResultTable.vue";
import OptimizedTable from "@/components/Table/OptimizesResultTable";
import DigitalPlanningTable from "@/components/Table/DigitalPlanningTable.vue";
import Toast from "@/components/Toast/Toast.vue";
// import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import ComparedPlans from "@/components/SavedPlans/ComparedPlans.vue";
import { AIDigitalPoem } from "@/services/AIDigitalPoemServices";
import Pagination from "../../widgets/Pagination.vue";
const aiDigitalPoem = new AIDigitalPoem();

export default {
  name: "SavedPlans",
  components: {
    "w-tab": Tab,
    // "w-white-dropdown": WhiteDropdown,
    SpaceTable,
    OptimizedTable,
    DigitalPlanningTable,
    Toast,
    ComparedPlans,
    Pagination,
  },
  data() {
    return {
      hostname: window.location.host,
      modelSelectMode: "single",
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      spaceTabs: [
        // {
        //   category: "Model",
        // },
        // {
        //   category: "Optimized Result",
        // },
        // {
        //   category: "Shortlisted plans",
        // },
        {
          category: "Saved plans",
        },
      ],
      tableData: [],
      optimizedTableData: [],
      digitalPlanningTableData: [],
      searchOptimiLoader: false,
      searchModelLoader: false,
      search: "",
      activeSpaceTab: "Saved plans",
      filterDropdown: [
        { text: "Personal space", id: "Personal space" },
        { text: "Shared space", id: "Shared space" },
      ],
      selectedFilter: { text: "Personal space", id: "Personal space" },
      comparePlans: false,
      searchModelText: "",
      searchOptimizedText: "",
      selectedRowData: [
        // {
        //   planName: "Summer Campaign",
        //   model: "Premium",
        //   marketKpi: ["USA", "Costs", "Clicks", "Engagements"],
        //   revenue: {
        //     previous: 568,
        //     predicted: 768,
        //   },
        //   budget: {
        //     previous: 568,
        //     predicted: 768,
        //   },
        //   createdOn: "21 Jun, 2021",
        // },
        // {
        //   planName: "Summer Campaign",
        //   model: "Premium",
        //   marketKpi: ["USA", "Costs", "Clicks", "Engagements"],
        //   revenue: {
        //     previous: 568,
        //     predicted: 768,
        //   },
        //   budget: {
        //     previous: 568,
        //     predicted: 768,
        //   },
        //   createdOn: "21 Jun, 2021",
        // },
      ],
      rowperPage: 5,
      currentPage: 1,
      totalRows: 0,
      selectedOptimizedRowData: [],
      optimizedrowperPage: 5,
      optimizedcurrentPage: 1,
      optimizedtotalRows: 0,
    };
  },
  methods: {
    deleteRow(e) {
      aiDigitalPoem
        .deleteSavedModel({ runId: e[0].runId, model: e[0].modelId })
        .then((res) => {
          this.deleteToast();
          this.getTabelData();
          console.log(res);
        });
    },
    deleteToast() {
      this.toastData.show = true;
      this.toastData.type = "success";
      this.toastData.message = "Your data has been deleted permanently";
    },
    deleteOptimizedRow(e) {
      aiDigitalPoem.deletSavedOptimizedResult(e[0]._id).then((res) => {
        this.deleteToast();
        this.getOptimizedTableData();
        console.log(res);
      });
    },
    getSearchedModelTableData() {
      this.searchModelLoader = true;
      aiDigitalPoem
        .searchModelResult({
          clientId: sessionStorage.getItem("subId"),
          text: this.searchModelText.toLowerCase(),
          currentPage: this.currentPage,
          rows: 10,
        })
        .then((res) => {
          this.tableData = [];
          for (var i = 0; i < res.values.length; i++) {
            this.tableData.push({
              planName: res.values[i].modelName,
              model: res.values[i].modelId,
              createdOn: res.values[i].saveDate,
              marketKpi: [
                res.values[i].kpi.country.charAt(0).toUpperCase() +
                  res.values[i].kpi.country.slice(1),
                res.values[i].kpi.targetCol.charAt(0).toUpperCase() +
                  res.values[i].kpi.targetCol.slice(1),
              ],
              modelId: res.values[i].model,
              runId: res.values[i].runId,
            });
          }
          this.totalRows = res.totalRows;
          this.searchModelLoader = false;
        })
        .catch(() => {
          this.searchModelLoader = false;
        });
    },
    searchByName(e) {
      this.searchModelText = e;
      if (e === "") {
        this.currentPage = 1;
        this.getTabelData();
        return;
      }
      this.currentPage = 1;
      this.getSearchedModelTableData();
    },
    getSearchedOptimizedTableData() {
      this.searchOptimiLoader = true;
      aiDigitalPoem
        .searchOptimiedResult({
          clientId: sessionStorage.getItem("subId"),
          text: this.searchOptimizedText.toLowerCase(),
          currentPage: this.optimizedcurrentPage,
          rows: 10,
        })
        .then((res) => {
          console.log(res)
          this.optimizedTableData = [];
          this.optimizedTableData = res.values;
          this.optimizedtotalRows = res.totalRows;
          this.searchOptimiLoader = false;
        })
        .catch(() => {
          this.searchOptimiLoader = false;
        });
    },
    searchByOptimiedName(e) {
      this.searchOptimizedText = e;
      this.optimizedcurrentPage = 1;
      if (e === "") {
        this.getOptimizedTableData();
        return;
      }
      this.getSearchedOptimizedTableData();
    },
    viewOptimizedResult(e) {
      sessionStorage.setItem("savedActualGrowth", e[0].document.growth * 100);
      sessionStorage.setItem("savedEdited", "notEdited");
      sessionStorage.setItem("savedTargetVariable", e[0].document.target);
      sessionStorage.setItem("optimizedSavedRunId", e[0].runId);
      sessionStorage.setItem("savedKpiConfig", e[0].kpiConfig);
      this.$router.push("/saved/model/results/optimized");
    },
    viewModelResult(e) {
      sessionStorage.setItem("savedRunId", e[0].runId);
      sessionStorage.setItem("savedModelId", e[0].modelId);
      this.$router.push("/saved/model/results");
    },
    getSelectedPageEvent(e) {
      if (this.searchModelText === "") {
        if (e !== this.currentPage) {
          this.currentPage = e;
          this.getTabelData();
        }
      } else {
        if (e !== this.currentPage) {
          this.getSearchedModelTableData();
        }
      }
    },
    getOptimizedSelectedPageEvent(e) {
      if (this.searchOptimizedText === "") {
        if (e !== this.optimizedcurrentPage) {
          this.optimizedcurrentPage = e;
          this.getOptimizedTableData();
        }
      } else {
        if (e !== this.optimizedcurrentPage) {
          this.getSearchedOptimizedTableData();
        }
      }
    },
    rowDataSelected(e) {
      this.selectedRowData = e;
    },
    optimizedrowDataSelected(e) {
      this.selectedOptimizedRowData = e;
    },
    changeSpaceTab(tabName) {
      this.activeSpaceTab = tabName;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updateToast(val) {
      this.toastData = val;
    },
    // selectFilter(e) {
    //   this.selectedFilter = e;
    //   this.getTabelData();
    // },
    getTabelData() {
      aiDigitalPoem
        .getSavedModels(
          sessionStorage.getItem("subId"),
          this.rowperPage,
          this.currentPage
        )
        .then((res) => {
          this.tableData = [];
          for (var i = 0; i < res.savedModels.length; i++) {
            this.tableData.push({
              planName: res.savedModels[i].modelName,
              model: res.savedModels[i].modelId,
              createdOn: res.savedModels[i].saveDate,
              marketKpi: [
                res.savedModels[i].kpi.country.charAt(0).toUpperCase() +
                  res.savedModels[i].kpi.country.slice(1),
                res.savedModels[i].kpi.targetCol.charAt(0).toUpperCase() +
                  res.savedModels[i].kpi.targetCol.slice(1),
              ],
              modelId: res.savedModels[i].model,
              runId: res.savedModels[i].runId,
            });
          }
          this.totalRows = res.totalRows;
        });
    },
    getOptimizedTableData() {
      aiDigitalPoem
        .getSavedOptimizedResult(
          sessionStorage.getItem("subId"),
          this.optimizedrowperPage,
          this.optimizedcurrentPage
        )
        .then((res) => {
          this.optimizedTableData = [];
          this.optimizedTableData = res.values;
          this.optimizedtotalRows = res.totalRows;
        });
    },
  },
  created() {
    this.getTabelData();
    this.getOptimizedTableData();
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-top: 71px;
  padding-top: 24px;
  overflow: hidden;
}
</style>
